.container {
    display: flex;
    justify-content: space-between;
}

.timelog {
    width: calc(70% - 40px);
}

.diagrams {
    display: flex;
}

.notFull {
    margin-bottom: 15px;
    flex-direction: row-reverse;
}

.full {
    flex-direction: column-reverse;
}

.pie {
    min-width: 270px;
    display: flex;
    justify-content: space-between;
}

.pieNotFull {
    width: 35%;
    flex-direction: column;
}

.pieFull {
    width: 100%;
    margin-bottom: 15px;
}

.information {
    width: 30%;
}

@media screen and (max-width: 1380px) {
    .timelog {
        width: calc(70% - 20px);
        margin-right: 20px;
    }

    .information {
        width: 30%;
    }
}

@media screen and (max-width: 1250px) {
    .diagrams {
        /* display: block; */
        flex-direction: column-reverse;
        margin-bottom: 15px;
    }

    .pie {
        width: 100%;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (max-width: 925px) {
    .container {
        display: block;
    }

    .timelog {
        width: 100%;
        margin-right: 0;
    }

    .information {
        width: 100%;
        margin: 20px 0;
    }
}

@media screen and (max-width: 725px) {
    .pie {
        display: flex;
        flex-direction: column;
    }
}