.specificTable {
    min-width: 500px;
}

.firstRow {
    margin-bottom: 1px;
    border-bottom: 1px solid #F2F2F3;
}

.firstRow .col {
    font-size: 12px;
    line-height: 16px;
}

.col {
    padding: 11px 15px;
    color: #403658;
}

.sort {
    cursor: pointer;
}

.active {
    background: #F8F8F8;
    border-radius: 5px;
}

.arrows {
    float: right;
    width: 4px;
    line-height: 3px;
}

.arrows svg {
    width: 6px;
    height: auto;
}

.specificTable {
    padding: 10px 20px 25px 20px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #0000000D;
}

.specificTable .row {
    padding: 15px 0;
}

.specificTable .col {
    padding: unset;
}

.specificTable .col:last-child {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}