.inputs {
    height: 35px;
    margin-bottom: 12px;
    /* padding: 11px 15px; */
    font-family: 'LATO medium';
}

.inputs::placeholder {
    font-family: 'LATO medium';
}

.editInputs svg {
    fill: #403658;
    width: 16px;
    height: auto;
}

.notFullWidth {
    width: 167px;
}

.notFullWidth .ant-select-selection-item {
    max-width: 150px;
    text-overflow: ellipsis;
}

.fullWidth {
    width: 100%;
}

.fullWidth .ant-select-selection-item {
    max-width: 330px;
}

.indent {
    margin-right: 11px;
}

.dropdown svg {
    fill: #000;
}

.custom.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 35px;
    border-radius: 5px;
}