@import '~antd/dist/antd.css';

@font-face {
  font-family: 'LEMON MILK Pro';
  src: url('./fonts/LEMONMILKPro-Regular.woff2') format('woff2'),
      url('./fonts/LEMONMILKPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'LATO medium';
  src: url('./fonts/Lato-Medium.woff') format('woff');
}

@font-face {
  font-family: 'LATO regular';
  src: url('./fonts/Lato-Regular.woff') format('woff');
}

@font-face {
  font-family: 'LATO bold';
  src: url('./fonts/Lato-Bold.woff') format('woff');
}

* {
  font-family: 'LEMON MILK Pro';
}

*::placeholder {
  color: #1D0C41 !important;
  opacity: 0.4 !important;
}

html {
  --antd-wave-shadow-color: none;
}

.modalsForm {
  display: flow-root;
  margin-left: 27px;
  margin-top: 20px;
  width: 345px;
}

.ant-input:focus, .ant-input-affix-wrapper-focused, .ant-select-selector, .ant-select-focused, .ant-picker-focused {
  box-shadow: unset !important;
  border: none !important;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  color: #403658;
}

.ant-input-prefix {
  margin-right: 11px;
}

.ant-select-single .ant-select-selector, .ant-select-selection-item {
  flex: none;
  align-items: center;
}

.ant-select-item-option-content, .ant-picker-input input, .ant-select-selection-item {
  font-size: 12px;
}

.custom .ant-select-item-option-content, .custom .ant-picker-input input, .custom .ant-select-selection-item {
  font-family: 'LATO medium';
}

.ant-picker-input input::placeholder {
  font-size: 12px;
}

.ant-pagination .ant-pagination-item a {
  font-family: 'LATO Medium';
  font-size: 12px;
  color: #403658;
  line-height: 24px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  border-radius: 5px;
  height: 45px;
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff;
}

.ant-pagination .ant-pagination-item-link svg {
  width: 10px;
  height: auto;
}

/* appexcharts */
.apexcharts-legend-series {
  display: flex;
  align-items: center;
}

.apexcharts-legend-marker {
  margin-right: 5px !important;
  width: 5px !important;
  height: 5px !important;
}

/* filters */
.ant-menu-root.ant-menu-vertical {
  box-shadow: 0px 3px 6px #00000029;
}

/* datepicker */
.ant-picker.ant-picker-range:hover {
  border-color: #d9d9d9;
}

.ant-picker-range .ant-picker-active-bar {
  background: #6732D9;
  width: 75px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before, 
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, 
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-range-hover-start .ant-picker-cell-inner::before,
.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, 
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, 
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #6732D910;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #6732D9;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #6732D9;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, 
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after {
  border-color: #6732D9;
}

.ant-picker-input > input {
  font-size: 11px;
  width: 75px;
}