.delete {
    margin-left: 10px;
    height: 35px;
    color: #DE6767;
    font-size: 12px;
    background: transparent;
    border: 1px solid #DE6767;
    border-radius: 5px;
}

.delete:hover {
    color: #DE6767;
    background: transparent;
    border: 1px solid #DE6767;
}

.delete:active {
    color: #DE6767;
    background: transparent;
    border: 1px solid #DE6767;
}